import { createApi } from "@reduxjs/toolkit/query/react"

import { baseQueryWithReauth, getApiConfig } from "shared/api"
import { currentUrl, hashUrl } from "../../url"

const { BASE_URL, prepareHeaders } = getApiConfig()
const GET_CATEGORIES_PATH = "master/tree"
const GET_SEARCH_PATH = "master/search/up-categories"
const MUTATE_CATEGORIES_PATH = "master/up-categories"
const SUB_CATEGORIES_PATH = "master/sub-categories"
const SERVICES_PATH = "master/services"

const BUSINESS_CATEGORIES_PATH = "master/directions"
// const BUSINESS_SUBCATEGORIES_PATH = "master/categories"

// const direction_id = 2 // FIX_ME

function transformGetCategoriesResponse({ data }) {
  return data.map(({ id, name, sub_category_services }) => ({
    id,
    name,
    subCategories: sub_category_services?.map(({ id, name, services }) => ({
      id,
      name,
      services: services?.map(({ id, name, price, duration }) => ({
        id,
        name,
        price,
        duration,
      })),
    })),
  }))
}

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  tagTypes: ["categories"],
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL, prepareHeaders }),
  endpoints: (builder) => ({
    // getAll
    getCategories: builder.query({
      query: (params) =>
        `/${GET_CATEGORIES_PATH}?page=${params.page}&perPage=${params.perPage}&limit=${params.limit}${params.search ? `&search=${params.search}` : ""}`,
      // providesTags: ({ data }) => {
      //   if (data) {
      //     return [
      //       ...data.map(({ id }) => ({ type: "categories", id })),
      //       { type: "categories", id: "LIST" },
      //     ]
      //   }
      //   return [{ type: "categories", id: "LIST" }]
      // },
      // transformResponse: transformGetCategoriesResponse,
    }),
    getCategoriesServices: builder.query({
      query: (params) =>
        `/${GET_CATEGORIES_PATH}?page=${params.page}&perPage=${params.perPage}&limit=${params.limit}${params.search ? `&search=${params.search}` : ""}`,
      // providesTags: ({ data }) => {
      //   if (data) {
      //     return [
      //       ...data.map(({ id }) => ({ type: "categories", id })),
      //       { type: "categories", id: "LIST" },
      //     ]
      //   }
      //   return [{ type: "categories", id: "LIST" }]
      // },
      // transformResponse: transformGetCategoriesResponse,
    }),
  getCategoriesServiseModal: builder.query({
    query: (params) =>
       `/${GET_CATEGORIES_PATH}?limit=${params.limit}`,
    // providesTags: ({ data }) => {
    //   if (data) {
    //     return [
    //       ...data.map(({ id }) => ({ type: "categories", id })),
    //       { type: "categories", id: "LIST" },
    //     ]
    //   }
    //   return [{ type: "categories", id: "LIST" }]
    // },
    // transformResponse: transformGetCategoriesResponse,
  }),
    getMasterCategories: builder.query({
      query: (params) => `/${GET_CATEGORIES_PATH}`,
      // providesTags: ({ data }) => {
      //   if (data) {
      //     return [
      //       ...data.map(({ id }) => ({ type: "categories", id })),
      //       { type: "categories", id: "LIST" },
      //     ]
      //   }
      //   return [{ type: "categories", id: "LIST" }]
      // },
      // transformResponse: transformGetCategoriesResponse,
    }),
    getSearchCategories: builder.query({
      query: (search) => `/${GET_SEARCH_PATH}?keyword=${search}`,
    }),
    // не получается делать post, нужен direction_id (id сферы деятельности)
    // post
    postCategory: builder.mutation({
      query: (body) => ({
        url: `/${MUTATE_CATEGORIES_PATH}`,
        method: "POST",
        body,
      }),
      // invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    // put
    updateCategory: builder.mutation({
      query: ({ name, id }) => ({
        url: `/${MUTATE_CATEGORIES_PATH}/${id}`,
        method: "PUT",
        body: { name },
      }),
      // invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    // delete
    removeCategory: builder.mutation({
      query: (id) => ({
        url: `/${MUTATE_CATEGORIES_PATH}/${id}`,
        method: "DELETE",
      }),
      // invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),

    searchService: builder.query({
      query: (search) => `${currentUrl}/master/services?keyword=${search}`,
    }),

    // subcategories api

    // post
    postSubCategories: builder.mutation({
      query: (body) => ({
        url: `/${SUB_CATEGORIES_PATH}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    // put
    updateSubCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${SUB_CATEGORIES_PATH}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    // delete
    removeSubCategory: builder.mutation({
      query: (id) => ({
        url: `/${SUB_CATEGORIES_PATH}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    addService: builder.mutation({
      query: (body) => ({
        url: `/${SERVICES_PATH}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    editService: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${SERVICES_PATH}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    removeService: builder.mutation({
      query: (id) => ({
        url: `/${SERVICES_PATH}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "categories", id: "LIST" }],
    }),
    getBusinessCategories: builder.query({
      query: () => `${BUSINESS_CATEGORIES_PATH}`,
    }),
  // Обновленный запрос с API ключом
getBusinessSubCategories: builder.query({
  query: (direction_ids) => {
    const id = Array.isArray(direction_ids) ? direction_ids.join(",") : direction_ids;
    return {
      url: `${currentUrl}/master/categories?direction_id=${id}`,
      headers: {
        'Navbat-key': 'kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr', // Убедитесь, что ключ правильный
      },
    };
  },
  prepareHeaders: (headers, { getState }) => {
    headers.set("Navbat-key", "kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr");
    return headers;
  },
}),

  }),
})

export const {
  useGetCategoriesQuery,
  useGetCategoriesServicesQuery,
  useGetMasterCategoriesQuery,
  usePostCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useGetSearchCategoriesQuery,
  useSearchServiceQuery,

  useGetCategoriesServiseModalQuery,

  useGetBusinessSubCategoriesQuery,
  usePostSubCategoriesMutation,
  useUpdateSubCategoryMutation,
  useRemoveSubCategoryMutation,

  useGetBusinessCategoriesQuery,

  useAddServiceMutation,
  useEditServiceMutation,
  useRemoveServiceMutation,
} = categoriesApi