import { flow, getParent, types } from "mobx-state-tree"
import { toast } from "sonner"
import { CategoriesService } from "resources"

export const ServiceModel = types
  .model("ServiceModel", {
    id: types.identifierNumber,
    name: types.string,
    price: types.string,
    duration: types.string,
    categoryId: types.number,
  })
  .actions((self) => ({
    update: flow(function* (data) {
      try {
        yield CategoriesService.updateService(self.id, data)
        getParent(self, 2).reload()
        toast.success("Услуга успешно удалена!", { position: "bottom-right" })
      } catch (error) {
        console.error(error)
        toast.error("Не удалось удалить. Попробуйте позже!")
      }
    }),
    delete: flow(function* () {
      try {
        yield CategoriesService.deleteService(self.id)
        getParent(self, 2).reload()
        toast.success("Услуга успешно удалена!", { position: "bottom-right" })
      } catch (error) {
        console.error(error)
        toast.error("Не удалось удалить. Попробуйте позже!")
      }
    }),
  }))