import React, { useCallback, useEffect, useMemo, useState } from "react"

import { Controller, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { MenuItem } from "@mui/material"

import { toast } from "sonner"
import { useEditBookingMutation } from "pages/bookings/newBookingApi"
import ChangeStatus from "./changeStatus"
import { SelectContained } from "shared/ui/select/SelectContained"
import { useGetMastersQuery } from "pages/masters/slice"
import { useParams } from "react-router-dom"
import {
  useGetAvailableTimeSlotsQuery,
  useGetBookingByHashQuery,
  useUpdateHashBookingMutation,
} from "./bookingHashApi"
import { TableSpinner } from "shared/ui/dataTable/spinner"
import NotFound from "./404"
import { useConvertToMinutes, useFormatDate } from "./hook"
import ChangeDate from "./edit"
import { addMinutes } from "date-fns"

const currentDate = new Date()

const ClientConfirmation = ({ refetch: calendarRefetch }) => {
  // * Hooks
  const formatDate = useFormatDate()
  const convert = useConvertToMinutes()
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm()

  // * API
  const [updateHashBooking, { isSuccess, isError }] =
    useUpdateHashBookingMutation()

  // * State
  const [date, setDate] = useState(null)
  const [time, setTime] = useState("")
  const [services, setServices] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [show404, setShow404] = useState(false)

  const [dateChange, setDateChange] = useState(false)
  const [timeChange, setTimeChange] = useState(false)

  // * Other
  const { hash } = useParams()

  // ? Изначальный запрос для получения данных брони по хэшу
  const {
    data: bookingData,
    isLoading: bookingDataLoading,
    error: bookingHashError,
  } = useGetBookingByHashQuery(hash)

  useEffect(() => {
    if (bookingData) {
      setServices(bookingData.services)
    }
  }, [bookingData])

  // ? Сумма всех услуг
  const servicesSum = useMemo(() => {
    return services.length
      ? services.reduce((prev, curr) => prev + Number(curr.price), 0)
      : 0
  }, [services])

  // ? Суммарное время
  const sumTime = useMemo(() => {
    return services.length
      ? services.reduce((prev, curr) => prev + convert(curr.duration), 0)
      : 0
  }, [services, convert])

  // ? Запрос, чтобы получить доступное время
  // ? Отправляется при выборе даты
  const { data: timeSlotsData } = useGetAvailableTimeSlotsQuery(
    {
      bookingId: bookingData?.booking?.id,
      hash: hash,
      masterId: bookingData?.booking?.master_id,
      serviceDuration: sumTime,
      date: formatDate(date),
    },
    {
      skip: !bookingData || !date || !sumTime,
    }
  )

  useEffect(() => {
    if (bookingData) {
      setDate(new Date(bookingData.booking.start))
      setTime(bookingData.booking.start.slice(-5))
    }
  }, [bookingData])

  // * Functions
  const onDateChange = (date, field) => {
    field.onChange(date)
    if (bookingData.booking.start.slice(0, -6) === formatDate(date)) {
      setDateChange(false)
      setDate(date)
    } else {
      setDateChange(true)
      setDate(date)
    }
  }

  useEffect(() => {
    if (bookingData) {
      if (timeSlotsData) {
        if (bookingData.booking.start.slice(0, -6) === formatDate(date)) {
          setDateChange(false)
        }
        if (bookingData.booking.start.slice(-5) === time) {
          setTimeChange(false)
        }
      }
    }
  }, [date, time, bookingData, setDateChange, formatDate, timeSlotsData])

  const onTimeChange = (time, field) => {
    field.onChange(field)
    setTime(time.target.value)
    setTimeChange(true)
  }

  const currentStatus = bookingData ? bookingData.booking.status : null

  const [activeStatusChange, setActiveStatusChange] = useState(false)
  const [freeTime, setFreeTime] = useState([])
  const [formStatus, setFormStatus] = useState("new")
  const [masters, setMasters] = useState([])
  const [updateBooking] = useEditBookingMutation()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const bookingVizit = bookingData?.booking
  const [statusId, setStatusId] = useState(bookingVizit?.status)
  const [salonId, setSalonId] = useState(0)
  const [clientId, setClientId] = useState(null)

  useEffect(() => {
    if (!bookingDataLoading && bookingData) {
      const clientId = bookingData?.booking?.client?.id
      setClientId(clientId)
    }
  }, [bookingDataLoading, bookingData])

  useEffect(() => {
    const salon = JSON.parse(localStorage.getItem("user"))
    setSalonId(salon?.salon?.id)
  }, [])

  useEffect(() => {
    const salon = JSON.parse(localStorage.getItem("user"))
    setSalonId(salon?.salon?.id)
  }, [])

  // Set form values when booking data changes
  useEffect(() => {
    if (bookingData && bookingData.start) {
      const date = new Date(bookingData.start)
      const time = bookingData.start.slice(11, 16)
      setValue("date", date)
      setValue("time", time)
      setSelectedDate(date) // Update selectedDate on bookingData change
    }
  }, [bookingData, setValue])

  useEffect(() => {
    if (bookingData && bookingData.start) {
      setValue("date", new Date(bookingData.start)) // Установка даты
      setValue("time", bookingData.start.slice(11, 16)) // Установка времени
    }
  }, [bookingData, setValue])

  useEffect(() => {
    if (!bookingDataLoading && bookingData) {
      const startDate = bookingData?.booking?.start?.slice(0, 10)
      if (startDate) {
        setValue("date", new Date(startDate))
      }
    }
  }, [bookingDataLoading, bookingData, masters.length, setValue])

  // * Перенос записи
  const editBooking = (data) => {
    // * Вычисление конца времени записи
    let end = new Date(`${formatDate(data.date)} ${time}`)

    end = addMinutes(end, sumTime)

    // ? Бэк не примет значения времения 9:00 или 10:1, поэтому нужно добавить ноль в начало
    const minutes =
      end.getMinutes() === 0 ? `${end.getMinutes()}0` : end.getMinutes()
    const hours =
      end.getHours().toString().length === 1
        ? `0${end.getHours()}`
        : end.getHours()

    const submit = {
      start: `${formatDate(data.date)} ${time}`,
      end: `${formatDate(data.date)} ${hours}:${minutes}`,
    }

    updateHashBooking({ body: submit, bookingId: bookingData.booking.id, hash })
  }

  // * Подтверждение
  const confirmBooking = () => {
    const submit = {
      status_id: 8,
    }
    updateHashBooking({ body: submit, bookingId: bookingData.booking.id, hash })
  }

  // * Отмена
  const cancelBooking = () => {
    const submit = {
      status_id: 3,
    }

    updateHashBooking({ body: submit, bookingId: bookingData.booking.id, hash })
  }

  useEffect(() => {
    if (bookingHashError) {
      if (bookingHashError?.status === 404) {
        setShow404(true)
      }
    }
  }, [bookingHashError])

  if (bookingDataLoading) {
    return <TableSpinner />
  }

  if (bookingHashError) {
    return <NotFound title={"Запись не найдена или истекла"} />
  }

  return (
    <form className=' w-full flex flex-col items-center bigMobile:gap-2 !overflow-y-auto no-scrollbar bigMobile:h-[100vh] h-full bg-[#FFFFFF] bigMobile:overflow-hidden mobile:mb-4'>
      <div className='text-center  w-full bigMobile:h-[55px] mobile:h-[65px] bg-[#1E3148] text-white bigMobile:p-2 py-4   '>
        <h2 className='text-2xl font-semibold'>Ваша запись</h2>
      </div>
      <div className='bg-white p-2   mb-2 w-full  max-w-[358px]  '>
        <div>
          <div className='flex  bg-[#F6FAFF] h-[52px] justify-between  mx-auto w-full p-2 rounded-lg '>
            <div>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                {bookingData && bookingData?.salon?.salon_type_id === 1
                  ? "Компания"
                  : "Частный специалист"}
              </p>
              <p className='font-medium text-medium '>
                {bookingData?.salon?.name || "N/A"}
              </p>
            </div>
            <div className='text-end'>
              <p className='text-gray-600 text-small font-normal text-[#A4AFBD] '>
                Специалист
              </p>
              <p className='font-medium text-medium'>
                {bookingData?.master?.name || "N/A"}
              </p>
            </div>
          </div>

          <h3 className='text-lg font-semibold mt-4'>Услуги</h3>

          <div className='border-t mt-2 pt-2 border-[#EDEDED] '>
            <div className='overflow-y-auto no-scrollbar h-full min-h-[80px] max-h-[120px] '>
              {bookingData ? (
                bookingData.services.map((service) => (
                  <div
                    key={service.id}
                    className='flex justify-between py-2 border-b border-[#EDEDED]'
                  >
                    <span>
                      <p className='font-medium text-medium'>{service.name}</p>
                      <small className='text-gray-600 text-small font-normal text-[#A4AFBD]'>
                        {service.upcategory
                          ? service.upcategory.name
                          : "Без категории"}
                      </small>
                    </span>
                    <span className='text-end'>
                      <p className='text-medium'>{service.price} TJS</p>
                      <small className='text-gray-600 text-small font-normal text-[#A4AFBD]'>
                        {Number(service.duration.split(":")[0])
                          ? `${Number(service.duration.split(":")[0])} ЧАС ${Number(service.duration.split(":")[1]) ? Number(service.duration.split(":")[1]) : ""} `
                          : `${Number(service.duration.split(":")[1])} МИН`}
                      </small>
                    </span>
                  </div>
                ))
              ) : (
                <p>No service available</p>
              )}
            </div>
            <div className='flex justify-between font-semibold py-4  px-2 bg-[#F6FAFF]'>
              <p>Итого</p>

              <span>
                <p className='font-medium text-medium '>{servicesSum}TJS</p>
                <small className='text-gray-600 text-small font-normal text-[#A4AFBD]'>
                  {sumTime} мин
                </small>
              </span>
            </div>
          </div>
        </div>
        <div className=' max-w-[358px] w-full mx-auto flex flex-col mb-4   justify-between gap-4'>
          <p className='text-gray-600 text-small font-normal text-[#A4AFBD] text-center mt-2 '>
            Вы можете перенести визит, изменив{" "}
            <span className='font-medium'>дату</span> и{" "}
            <span className='font-medium'>время!</span>{" "}
          </p>

          <div className='col-span-3 flex flex-col sm:flex-row gap-3 sm:gap-0 justify-between [&>label]:w-full [&>label]:flex [&>label]:flex-col '>
            <label className='label-base sm:mr-2'>
              <span className='font-medium text-main'>Дата</span>
              <Controller
                control={control}
                rules={{ required: "Заполните это поле" }}
                name='date'
                render={({ field }) => (
                  <DatePicker
                    showIcon
                    disabled={
                      currentStatus.id === 3 || currentStatus.id === 6
                      // ||
                      // currentStatus.id === 8
                    }
                    minDate={currentDate}
                    defaultValue={bookingData.booking.start}
                    selected={field.value}
                    onChange={(date) => onDateChange(date, field)}
                    placeholderText='мм.дд.гггг'
                    dateFormat='dd-MM-yyyy'
                    style={{ paddingLeft: "20px" }}
                    className='inputOutlined mt-1 w-full text-left !pl-10'
                    icon={
                      <div className='mt-[9px] mr-4'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_8637_17044)'>
                            <path
                              d='M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z'
                              fill='#A4AFBD'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_8637_17044'>
                              <rect width='24' height='24' fill='white' />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    }
                  />
                )}
              />

              {errors.date && (
                <p className='text-red'>{errors?.date?.message}</p>
              )}
            </label>

            <label className='label-base relative sm:ml-2'>
              <span className='font-medium text-main'>Время</span>
              <Controller
                control={control}
                name='time'
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={formStatus === "edit"}
                // rules={{
                //   required: "Заполните это поле",
                // }}
                render={({ field }) => (
                  <div className='relative w-full'>
                    <div className='absolute left-2 top-[14px] z-10 '>
                      <AccessTimeIcon color='disabled' />
                    </div>
                    <SelectContained
                      defaultValue={bookingData?.booking?.start?.split(" ")[1]}
                      {...field}
                      time
                      renderValue
                      onChange={(time) => onTimeChange(time, field)}
                      className='mt-[3px]'
                      disabled={
                        currentStatus.id === 3 || currentStatus.id === 6
                        // ||
                        // currentStatus.id === 8
                      }
                    >
                      {/* {dateChange || timeChange
                        ? null
                        : bookingData && (
                            <MenuItem
                              selected
                              value={bookingData?.booking?.start?.split(" ")[1]}
                            >
                              {bookingData?.booking?.start?.split(" ")[1]}
                            </MenuItem>
                          )}

                      {!timeSlotsData ? (
                        <MenuItem disabled>Нет свободного времени</MenuItem>
                      ) : timeSlotsData.length === 0 ? (
                        <MenuItem disabled>Нет свободного времени</MenuItem>
                      ) : (
                        timeSlotsData?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      )} */}

                      {(timeSlotsData || timeSlotsData?.length === 0) &&
                      !dateChange ? (
                        <MenuItem
                          selected
                          value={bookingData.booking.start.slice(-5)}
                        >
                          {bookingData.booking.start.slice(-5)}
                        </MenuItem>
                      ) : null}

                      {!timeSlotsData || timeSlotsData.length === 0 ? (
                        <MenuItem disabled>Нет свободного времени</MenuItem>
                      ) : !dateChange ? (
                        timeSlotsData.map((slot) => (
                          <MenuItem key={slot} value={slot}>
                            {slot}
                          </MenuItem>
                        ))
                      ) : null}

                      {dateChange ? (
                        <MenuItem selected value={timeSlotsData[0]}>
                          {timeSlotsData[0]}
                        </MenuItem>
                      ) : null}

                      {dateChange
                        ? timeSlotsData.map((slot, index) => (
                            <MenuItem key={slot} value={slot}>
                              {slot}
                            </MenuItem>
                          ))
                        : null}
                    </SelectContained>
                  </div>
                )}
              />
              {errors.time && <p className='text-red'>{errors.time.message}</p>}
            </label>
          </div>

          <div className='flex flex-col justify-between gap-2   '>
            <label className='label-base relative sm:ml-2'>
              <span>Статус</span>
            </label>
            <span
              className={`rounded-lg w-full py-3 text-left px-4 items-center mb-6 font-[400] text-medium h-[48px]
                      ${
                        !currentStatus
                          ? ""
                          : currentStatus.id === 4
                            ? "text-[#92400E] bg-[#FEF3C7]"
                            : currentStatus.id === 3
                              ? "text-[#92400E] bg-[#FEE2E2]"
                              : currentStatus.id === 8
                                ? "bg-[#edf1f7]"
                                : "bg-[#D1FAE5] text-[#065F46]"
                      }`}
            >
              {currentStatus &&
                `Запись ${currentStatus.id === 4 ? "в ожидании" : currentStatus.id === 3 ? "отменена" : currentStatus.id === 6 ? "оплачена" : currentStatus.id === 8 ? "подтверждена" : null}`}
            </span>

            {!(
              currentStatus.id === 3 ||
              currentStatus.id === 6 ||
              currentStatus.id === 8
            ) && (
              <button
                type='button'
                className={`px-4 py-2 w-full rounded-lg text-white ${dateChange || timeChange ? "bg-darkGray" : "bg-darkOrange"}`}
                onClick={() => setShowConfirm(true)}
                disabled={dateChange || timeChange}
              >
                Подтвердить
              </button>
            )}
            <button
              type='button'
              disabled={!(dateChange || timeChange)}
              className={`px-4 py-2 w-full border rounded-lg border-darkGray font-medium ${!(dateChange || timeChange) ? "border-darkGray text-darkGray" : "text-darkOrange border-darkOrange"}`}
              onClick={() => setShowEdit(true)}
            >
              Сохранить изменения
            </button>
            <button
              type='button'
              disabled={currentStatus.id === 3 || currentStatus.id === 6}
              className={`px-4 py-2 w-full rounded-lg font-medium ${(currentStatus.id === 3 || currentStatus.id === 6) && " text-darkGray"}`}
              onClick={() => setShowCancel(true)}
            >
              Отменить запись
            </button>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showConfirm && (
        <ChangeStatus
          setActive={setShowConfirm}
          onClose={() => setShowConfirm(false)}
          active={showConfirm}
          onConfirm={confirmBooking}
          closeStatusModal={() => setShowConfirm(false)}
          currentStatusId={statusId}
          text={"Вы уверены что хотите подтвердить запись?"}
          isSuccess={isSuccess}
          successLabel={"Ваша запись успешно подтверждена"}
          errorLabel={"При подтверждении произошла ошибка"}
        />
      )}
      {showCancel && (
        <ChangeStatus
          isError={isError}
          isSuccess={isSuccess}
          setActive={setShowCancel}
          onClose={() => setShowCancel(false)}
          active={showCancel}
          onConfirm={cancelBooking}
          closeStatusModal={() => setShowCancel(false)}
          currentStatusId={statusId}
          text={"Вы уверены что хотите отменить запись?"}
          successLabel={"Ваша запись успешно отменена"}
          errorLabel={"При отмене произошла ошибка"}
        />
      )}
      {showEdit && (
        <ChangeDate
          closeStatusModal={() => setShowEdit(false)}
          onClose={() => setShowEdit(false)}
          active={() => setShowEdit(true)}
          onConfirm={handleSubmit(editBooking)}
          currentStatusId={statusId}
          isSuccess={isSuccess}
          isError={isError}
          text={"Вы уверены, что хотите перенести визит?"}
        />
      )}
    </form>
  )
}

export default ClientConfirmation