import React from "react"
import { DeleteModal } from "../../../shared/ui/modal/DeleteModal"
import { toast } from "sonner"
import { useDeleteTemplateMutation } from "../../../pages/laboratory/templates/templatesApi"

export const RemoveTemplate = ({ setActive, active, template }) => {
  const [deleteTemplate, { isLoading }] = useDeleteTemplateMutation()
  const onSubmit = async (id) => {
    try {
      await deleteTemplate(id).unwrap()

      toast.success("Шаблон успешно удалён!", { position: "bottom-right" })
      setActive(false)
    } catch (err) {
      console.log(err)
      toast.error("Ошибка при удалении шаблона!")
      setActive(false)
    }
  }

  return (
    <DeleteModal
      loading={isLoading}
      text='Вы действительно хотите удалить этот шаблон?'
      id={template.id}
      active={active}
      setActive={setActive}
      onSubmit={onSubmit}
    />
  )
}

