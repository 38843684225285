import React from "react"
import { Paper } from "@mui/material"
import { toast } from "sonner"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { InputBase } from "shared/ui/input/InputBase"
import { Controller, useForm } from "react-hook-form"
import { useSendMessageMutation } from "./feedbackApi"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const loadJivoScript = () => {
  const script = document.createElement("script")
  script.src = "//code.jivo.ru/widget/AYsIgDEYrr"
  script.async = true
  document.head.appendChild(script)
  return script
}

export function Feedback() {
  const { status_id } = useSelector((state) => state.settingsSliceReducer)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm()
  const [sendMessage, { isLoading: messageLoading }] = useSendMessageMutation()
  const [user, setUser] = useState(null)
  const location = useLocation()

  useEffect(() => {
    let jivoScript = null
    if (location.pathname === "/feedback") {
      jivoScript = loadJivoScript()
    }

    return () => {
      if (jivoScript) {
        document.head.removeChild(jivoScript)
      }
    }
  }, [location.pathname])

  const onSubmit = async (data) => {
    const formData = new FormData()
    formData.append("name", user?.name)
    formData.append("phone", `+992${user?.phone}`)
    formData.append("message", data["message"])
    formData.append("mail", data["mail"])
    try {
      await sendMessage({ formData }).unwrap()
      reset()
      toast.success("Ваше обращение отправлено", { position: "bottom-right" })
    } catch (err) {
      toast.error("Ошибка при отправке сообщения, попробуйте еще раз")
    }
  }

  return (
    <Paper
      sx={{ padding: "24px 12px" }}
      className='sm:w-full w-[358px] mx-auto sm:mx-[0] my-auto sm:my-[0]  '
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className='flex justify-between items-center'>
            <h2 className='title'>Сообщения</h2>
          </div>
          <p className='text-sm text-darkGray'>
            Если у вас есть какие-то вопросы или предложения - заполните форму
            ниже
          </p>
        </div>
        <div className='flex flex-col [&>label]:flex [&>label]:flex-col'>
          <div>
            <Controller
              name='mail'
              control={control}
              rules={{
                required: "Заполните это поле",
                minLength: {
                  value: 5,
                  message: "Введите минимум 5 символов ",
                },
              }}
              render={({ field }) => (
                <InputBase
                  {...field}
                  labelText='Тема'
                  labelClassName='mt-4'
                  inputClassName='inputOutlined'
                  placeholder='Введите тему обращения'
                  errorCondition={errors["mail"]}
                  errorText={errors["mail"]?.message}
                />
              )}
            />
            <ErrorMsg title={errors["mail"]?.message} />
          </div>

          <label className='label-base relative mt-10'>
            Сообщение
            <textarea
              {...register("message", {
                required: "Заполните это поле",
                minLength: {
                  value: 5,
                  message: "Минимум 5 символов",
                },
              })}
              className={`inputOutlined resize-none sm:h-64 h-48 ${
                errors["message"]?.message &&
                "border-red-500 hover:border-red-500 focus:border-red-500"
              }`}
              maxLength={1500}
              placeholder='Введите ваше сообщение'
            />
            <ErrorMsg title={errors["message"]?.message} />
          </label>

          <div className='flex items-center [&>button]:pl-5 [&>button]:pr-5 mt-10'>
            <button
              disabled={messageLoading}
              className={
                messageLoading ? "opacity-[50%] btnFilled" : "btnFilled"
              }
            >
              Отправить
            </button>
          </div>
        </div>
      </form>
    </Paper>
  )
}