import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../url"

const accessToken = localStorage.getItem("accessToken")

export const customersApi = createApi({
  reducerPath: "customersApi",
  tagTypes: "Customers",
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `${accessToken}`)
        headers.set("Accept", "application/json")
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    },
  }),
  endpoints: (build) => ({
    getCustomers: build.query({
      query: (params) =>
        `/master/search/clients?keyword=${params.search}&salon_id=${params.salonId}&page=${params.page}&perPage=${params.perPage}&limit=${params.limit}`,
    providesTags: (result) => {
  if (result?.data?.length > 0) {
    return [
      ...result.data.map(({ id }) => ({ type: "Customers", id })),
      { type: "Customers", id: "LIST" },
    ];
  }
  return [{ type: "Customers", id: "LIST" }]; // Добавление тега даже для пустого списка
},

    }),
    updateCustomer: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/master/clients/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Customers", id: "LIST" }],
    }),
  }),
})

export const {useGetCustomersQuery, useUpdateCustomerMutation} = customersApi
