import React from "react"
import { getApiConfig} from "shared/api" ;
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "sonner"
import { currentUrl } from "../../url"

const { accessToken } = getApiConfig()

type CategoriesSearch = {
  id: number
  name: string
}

type Service = {
  id: number
  name: string
  price: number
  duration: string
  up_category_id: number | null
  sub_category_id: number | null
}

type Subcategory = {
  id: number
  up_category_id: number
  name: string
  services: Service[] | []
}

type Category = {
  id: number
  name: string
  services: Service[] | []
  subcategory: Subcategory[] | []
}

type CategoriesState = {
  categories: Category[]
  list: CategoriesSearch[]
  search: string
  loading: boolean
  error: boolean
}

export const fetchCategories = createAsyncThunk<
  Category[],
  undefined,
  { rejectValue: boolean }
>("categories/fetchCategories", async function (_, { rejectWithValue }) {
  try {
    const res = await fetch(`${currentUrl}/master/tree`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return await res.json()
  } catch (err) {
    rejectWithValue(true)
    toast.error("Ошибка при загрузке данных, попробуйте позже")
  }
})

export const fetchSearchCategories = createAsyncThunk<
  CategoriesSearch[],
  string,
  { rejectValue: boolean }
>("categories/SearchCategories", async function (search, { rejectWithValue }) {
  try {
    const res = await fetch(
      `${currentUrl}/master/search/up-categories?keyword=${search}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    return await res.json()
  } catch (err) {
    toast.error("Ошибка при загрузке данных, попробуйте позже")
  }
})

const initialState: CategoriesState = {
  categories: [],
  list: [],
  loading: false,
  error: false,
  search: "",
}

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true
        state.error = true
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false
        state.categories = action.payload
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      .addCase(fetchSearchCategories.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchSearchCategories.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.list = action.payload
      })
      .addCase(fetchSearchCategories.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export default categoriesSlice.reducer
export const { setSearch } = categoriesSlice.actions